<ng-container *ngIf="(palette && palette.cartons?.length > 0 && palette.status === 1)">
    <div class="row">
      <div class="col">
        <div class="d-grid gap-2">
          <button appNoDblClick (click)="printBarcodeStickerPdf()" class="btn btn-primary btn-lg" type="button">
            <i class="bi bi-printer"></i>
            Imprimer l'étiquette de la Palette
          </button>
        </div>
      </div>
    </div>
  
    <br>
</ng-container>
  
<ng-container *ngIf="!isAdmin && (palette && (palette.cartons?.length >= 0) && (paletteSize > 0) && (palette.cartons?.length <= (paletteSize-1)) || (palette.status === 0 && paletteSize === -1) )">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Scan du code-barre</h5>
          <h6 class="card-subtitle mb-2 text-muted">Veuillez scanner le code-barre du Carton</h6>
          <p class="card-text" #scanStatus id="scanStatus">En attente du scan</p>
          <!--<input #barcodeInput name="barcodeInput" type="text" class="form-control" (keyup)="onKeyBarcode(barcodeInput)" title="scanInput" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" autofocus>-->
        </div>
      </div>
    </div>
  </div>

  <br>
</ng-container>

<div class="row">
  <div class="col">
    <div *ngIf="(palette && palette.cartons?.length > 0)">

      <div class="card">
        <div class="card-body">
          
          <ng-container *ngFor="let d of paletteModeleDetails" [ngSwitch]="d.product_type?.toString()">
            <ng-container *ngIf="palette.cartons.length === 1">
              <h3>Carton de {{d.product_type | cartonType}}</h3>
            </ng-container>
            <ng-container *ngIf="palette.cartons.length > 1">
              <h3>Cartons de {{d.product_type | cartonType}}</h3>
            </ng-container>
            <h4 *ngSwitchCase="'G'" class="card-subtitle mb-2 text-muted">{{ cartonsCassettes.length || '0' }} sur {{d.nbCartons}}</h4>
            <h4 *ngSwitchCase="'B'" class="card-subtitle mb-2 text-muted">{{ cartonsBaguettes.length || '0' }} sur {{d.nbCartons}}</h4>
            <h4 *ngSwitchCase="'C'" class="card-subtitle mb-2 text-muted">{{ cartonsCanettes.length || '0' }} sur {{d.nbCartons}}</h4>
            <h4 *ngSwitchCase="'T'" class="card-subtitle mb-2 text-muted">{{ cartonsFusettes.length || '0' }} sur {{d.nbCartons}}</h4>
          
            <br>

            <div class="container mb-4">
              <div class="row">
                <ng-container *ngFor="let carton of palette.cartons">
                  <div *ngIf="carton.type === d.product_type"  class="mb-3 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <input (click)="openCartonDetail($event, carton.id)" value="{{carton.serialNumber | cartonBarcode: carton.provider.id}}" type="text" maxlength="10" class="text-center form-control numFSinput input-carton" aria-label="Small" aria-describedby="inputGroup-sizing-sm" style="text-transform:uppercase" autocomplete="off" readonly="" title="resultInput">
                    <ng-container *ngIf="!isAdmin">
                      <div *ngIf="palette.status === 0">
                        <button appNoDblClick (click)="removeCarton($event)" class="btn btn-outline-secondary fs-delete" type="button">
                          <i class="bi bi-x-lg"></i>
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>

        </div>
      </div>

    </div>
  </div>

</div>

<canvas id="barcode" style="visibility:hidden; display: none;"></canvas>