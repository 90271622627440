import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'livraisonDocumentType',
    standalone: false
})
export class LivraisonDocumentTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'bonLivraison':
        return 'Demande de Livraison';
      case 'detailLivraison':
        return 'Détail de la Livraison';
      case 'cgmadMateriel':
        return 'Conditions Générales de Mise à Disposition du Matériel';
      case 'attestationAssurance':
        return 'Attestation d\'Assurance';
      case 'attestationHonneurStock':
        return 'Attestation sur l’Honneur de mon Stock de Filtres Stabilisateurs®';
      default:
        return 'Document Inconnu';
    }
  }
}
