import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {FournituresService} from '../services/fournitures.service';

@Component({
    selector: 'app-fournitures-commandes',
    templateUrl: './fournitures-commandes.component.html',
    styleUrls: ['./fournitures-commandes.component.css'],
    standalone: false
})
export class FournituresCommandesComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  provider: any = -1;
  commandes: any = [];
  error: string = '';
  userIsLoggedin: boolean = false;

  livraisonCreated: any = null;
  errorMessage: string = '';

  constructor(private router: Router, private fournituresService: FournituresService, private authService: AuthService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      // console.log(this.decodedToken);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      this.provider = this.decodedToken.provider;
      // admin needs to see all ads
      if (this.isAdmin) {
        this.loadCommandes();
      } else {
        this.loadCommandes();
      }

      this.fournituresService.fournituresSubject.subscribe(data => {
        this.commandes = [data, ...this.commandes];
      });
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadCommandes() {
    this.fournituresService.getCommandes(this.token)
      .subscribe({
        next: (data) => this.displayCommandes(data),
        error: (err) => console.error(err),
        complete: () => console.log("commands are loaded!")
      }
      );
  }

  displayCommandes(response: any = {}) {
    // console.log('response ', response);

    if (response.success) {
      console.log('commandes ', response.commandes);
      this.commandes = response.commandes;
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }
}
