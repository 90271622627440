import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.css'],
    standalone: false
})
export class StatsComponent implements OnInit {

	ngOnInit(): void {
		window.scrollTo(0, 0);
	}
	
}