import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {PaletteService} from '../services/palette.service';
import {Palette} from '../model/palette';

@Component({
    selector: 'app-palette-list',
    templateUrl: './palette-list.component.html',
    styleUrls: ['./palette-list.component.css'],
    standalone: false
})
export class PaletteListComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  providers: any = [];
  provider: any = null;
  palettes: Palette[] = [];
  palettesStatus0: Palette[] = [];
  palettesStatus1: Palette[] = [];
  palettesCassettes: Palette[] = [];
  palettesCustom: Palette[] = [];
  palettesP3P: Palette[] = [];
  palettesP4P: Palette[] = [];
  palettesP3PB: Palette[] = [];
  palettesPBG: Palette[] = [];
  palettesPCN: Palette[] = [];
  palettesPFU: Palette[] = [];
  error: string = '';
  userIsLoggedin: boolean = false;

  paletteCreated: any = {};
  errorMessage: string = '';

  constructor(private router: Router, private paletteService: PaletteService, private authService: AuthService) { }

  ngOnInit() {
    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      this.provider = this.decodedToken.provider;
      
      if (this.isAdmin) {
        this.loadPalettes();
      } else {
        this.loadPalettes();
      }

      // this.paletteService.palettesSubject.subscribe(
      //   {
      //     next: data => this.palettes = data,
      //     error: err => console.error(err),
      //     complete: () => console.log('palettes subject loaded!')
      //   }
      // );
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadPalettes() {
    this.paletteService.getPalettes(this.token)
      .subscribe({
        next: data => this.displayPalettes(data),
        error: err => console.error(err),
        // complete: () => console.log('palettes loaded!')
      })
  }

  displayPalettes(response: any = {}) {

    if (response.success) {
      this.palettes = response.palettes;
      this.palettesStatus0 = this.palettes.filter((palette: { status: number; }) => palette.status === 0);
      this.palettesStatus1 = this.palettes.filter((palette: { status: number; }) => palette.status === 1);
	  
	    this.palettesCassettes = this.palettesStatus1.filter((palette: { modele: number; }) => palette.modele === 1);
	    this.palettesCustom = this.palettesStatus1.filter((palette: { modele: number; }) => palette.modele === 2);
	    this.palettesP3P = this.palettesStatus1.filter((palette: { modele: number; }) => palette.modele === 3);
	    this.palettesP4P = this.palettesStatus1.filter((palette: { modele: number; }) => palette.modele === 4);
	    this.palettesP3PB = this.palettesStatus1.filter((palette: { modele: number; }) => palette.modele === 7);
	    this.palettesPBG = this.palettesStatus1.filter((palette: { modele: number; }) => palette.modele === 8);
	    this.palettesPCN = this.palettesStatus1.filter((palette: { modele: number; }) => palette.modele === 9);
	    this.palettesPFU = this.palettesStatus1.filter((palette: { modele: number; }) => palette.modele === 10);

      if (this.isAdmin) {
        const providersPalettes = this.palettes.map((palette: { provider: any; }) => palette.provider);

        const uniqueCartonsArray = (data: any[]) => [...new Set(data.map((o: any) => JSON.stringify(o)))].map(s => JSON.parse(s.toString()));
        this.providers = uniqueCartonsArray(providersPalettes).sort(function(a, b) {
          return a.id - b.id;
        });

        for (let p = 0; p < this.providers.length; p++) {
          this.providers[p]['palettesStatus0'] = this.palettesStatus0.filter((palette: { provider: { id: any; }; }) => palette.provider.id === this.providers[p].id);
          this.providers[p]['palettesStatus1'] = this.palettesStatus1.filter((palette: { provider: { id: any; }; }) => palette.provider.id === this.providers[p].id);
          this.providers[p]['palettesCassettes'] = this.providers[p]['palettesStatus1'].filter((palette: { modele: number; }) => palette.modele === 1);
          this.providers[p]['palettesCustom'] = this.providers[p]['palettesStatus1'].filter((palette: { modele: number; }) => palette.modele === 2);
          this.providers[p]['palettesP3P'] = this.providers[p]['palettesStatus1'].filter((palette: { modele: number; }) => palette.modele === 3);
          this.providers[p]['palettesP4P'] = this.providers[p]['palettesStatus1'].filter((palette: { modele: number; }) => palette.modele === 4);
          this.providers[p]['palettesP3PB'] = this.providers[p]['palettesStatus1'].filter((palette: { modele: number; }) => palette.modele === 7);
          this.providers[p]['palettesPBG'] = this.providers[p]['palettesStatus1'].filter((palette: { modele: number; }) => palette.modele === 8);
          this.providers[p]['palettesPCN'] = this.providers[p]['palettesStatus1'].filter((palette: { modele: number; }) => palette.modele === 9);
          this.providers[p]['palettesPFU'] = this.providers[p]['palettesStatus1'].filter((palette: { modele: number; }) => palette.modele === 10);
        }
      }
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }


  createPalette() {
    const palette: Palette = <Palette><unknown>{
      id: Date.now(),
      provider: { id: this.provider.id },
      serialNumber: 0,
      status: 0,
      modele: 1,
      type: '',
      idLivraison: 0,
      barcode: '',
      creationDate: new Date().toISOString(),
      cartons: [],
      paletteSize: 1 // Le temps de déterminer la contenance avec le premier scan.
    };

    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.paletteService.addPalette(palette, token).subscribe(
      {
        next: data => this.handleServerResponse(data),
        error: err => console.error(err),
        complete: () => console.log('palette was added!')
      }
    );
  }

  handleServerResponse(response: { success: any; palette: any; message: string; }) {
    // console.log('handleServerResponse', response);

    if (response.success) {
      // console.log('ON PASSE PAR LÀ');
      this.paletteCreated = response.palette;
      // console.log('this.paletteCreated', this.paletteCreated);
      this.router.navigateByUrl(`/palette/${this.paletteCreated.id}`);
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  handleError(error: any = {}) {
    console.log('handleError ', error.statusText);
    this.error = error;
  }

}
