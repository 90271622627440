import { Injectable } from '@angular/core';
import {AuthService} from './auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventaireService {

  constructor(private http: HttpClient, private authService: AuthService) {}

  getInventaire(token: string) {
    console.log('getInventaire FRANCHISE');
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/inventaire`, { headers });
  }

  getCartonsFromInventaire(id: any, token: string) {
    console.log(`getCartonsFromInventaire ID : ${id}`);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/inventaire/${id}/cartons`, { headers });
  }

  getProductsFromInventaire(id: any, token: string) {
    console.log(`getProductsFromInventaire ID : ${id}`);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/inventaire/${id}/products`, { headers });
  }
}
