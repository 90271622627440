import { Component, OnInit } from '@angular/core';
import { LivraisonService } from '../services/livraison.service';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-livraisons-history',
    templateUrl: './livraisons-history.component.html',
    styleUrls: ['./livraisons-history.component.css'],
    standalone: false
})
export class LivraisonsHistoryComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  provider: number = -1;
  livraisons: any = [];
  error: string = '';
  userIsLoggedin: boolean = false;

  livraisonCreated: any;
  errorMessage: string = '';

  currentPage: number = 1;
  pageCount: number = 1;
  pages: number[] = [];

  constructor(private livraisonService: LivraisonService, private authService: AuthService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      this.provider = this.decodedToken.provider;
      if (this.isAdmin) {
        this.loadLivraisons();
      } else {
        this.loadLivraisons();
      }

      this.livraisonService.livraisonsSubject.subscribe(
      {
        next: data => this.livraisons = data,
        error: err => console.error(err),
        complete: () => console.log('livraisonService subject loaded!')
      });
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadLivraisons(page = 1) {
    this.livraisonService.getLivraisonsHistory(page, this.token)
      .subscribe(
        {
          next: data => this.displayLivraisons(data),
          error: err => console.error(err),
          complete: () => console.log('livraison history loaded!')
        }
      );
  }

  displayLivraisons(response: any = {}) {
    if (response.success) {
      this.currentPage = response.currentPage;
      this.pageCount = response.pageCount;
      this.livraisons = response.livraisons;

      const pages = new Array(this.pageCount);
      for (let i = 1; i <= this.pageCount; i++) {
        pages[i - 1] = i;
      }

      this.pages = pages;
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }
}
