import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LivraisonAsiService } from '../services/livraison-asi.service';
import { LivraisonAsi } from '../model/livraison_asi';
import { Franchise } from '../model/franchise';
import { Provider } from '../model/provider';
import { Livraison } from '../model/livraison';

@Component({
    selector: 'app-livraison-asi-list',
    templateUrl: './livraison-asi-list.component.html',
    styleUrls: ['./livraison-asi-list.component.css'],
    standalone: false
})
export class LivraisonAsiListComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  providers: [] = [];
  provider: any = null;
  livraisons: LivraisonAsi[] = [];
  livraisons0: LivraisonAsi[] = [];
  livraisons1: LivraisonAsi[] = [];
  error: string = '';
  userIsLoggedin: boolean = false;

  livraisonCreated: any;
  errorMessage: string = '';

  constructor(private router: Router, private livraisonService: LivraisonAsiService, private authService: AuthService) {
  }

  ngOnInit() {
    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      this.provider = this.decodedToken.provider;
      if (this.isAdmin) {
        this.loadLivraisons();
      } else {
        this.loadLivraisons();
      }

      // this.livraisonService.livraisonsSubject.subscribe(data => {
      //   this.livraisons = [data, ...this.livraisons];
      // });
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadLivraisons() {
    this.livraisonService.getLivraisons(this.token)
      .subscribe(
        {
          next: data => this.displayLivraisons(data),
          error: err => console.error(err),
          complete: () => console.log('livraisons asi loaded!')
        }
      );
  }

  displayLivraisons(response: any = {}) {
    console.log("livraisons list ", response)
    if (response.success) {
      this.livraisons = response.livraisons;

      this.livraisons0 = this.livraisons.filter(livraison => livraison.status === 0);
      this.livraisons1 = this.livraisons.filter(livraison => livraison.status === 1);
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  showHistory() {
    this.router.navigate([`/livraisons-asi/historique`]);
  }

  createLivraison() {
    const livraison: LivraisonAsi = <LivraisonAsi>{
      id: Date.now(),
      provider: { id: this.provider.id },
      serialNumber: 0,
      status: 0,
      franchise: <Franchise>{ id: 0, initiales: '' },
      barcode: '',
      creationDate: new Date().toISOString(),
      finalizationDate: new Date().toISOString(),
      shipmentDate: new Date().toISOString()
    };

    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.livraisonService.addLivraison(livraison, token).subscribe(
      {
        next: data => this.handleServerResponse(data),
        error: err => this.handleError(err),
        complete: () => console.log('livraison was created!')
      }
    );
  }

  handleServerResponse(response: any = {}) {
    console.log('response after created', response)
    if (response.success) {
      this.livraisonCreated = response.livraison;
      this.router.navigateByUrl(`/livraison-asi/${this.livraisonCreated.id}`);
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  handleError(error: any = {}) {
    console.log('handleError ', error.statusText);
    this.error = error;
  }

}
