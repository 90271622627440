import { Component, Input, Output, HostListener, EventEmitter, OnInit } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { ToastService } from '../services/toast.service';
import { ProductRetourService } from '../services/product-retour.service';

@Component({
    selector: 'app-scan-products-retour',
    templateUrl: './scan-products-retour.component.html',
    styleUrls: ['./scan-products-retour.component.css'],
    standalone: false
})
export class ScanProductsRetourComponent {
  @Input() retour: any;
  @Input() centreProducts: any[] = [];
  @Input() token: string = '';
  @Input() retourDetailComponent: any;

  decodedToken: any = {};
  isAdmin: boolean = false;
  isCentre: boolean = false;

  scannedBarcode: string = '';

  constructor(private productRetourService: ProductRetourService,
    private alertService: AlertService,
    private toastService: ToastService,
    private authService: AuthService) { }

  ngOnInit() {
    if (this.token) {
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken) {
        if (this.decodedToken.role.nom === 'admin')
          this.isAdmin = true;
        if (this.decodedToken.role.nom === 'centre')
          this.isCentre = true;
      }
    }
  }


  alertPrimary(message: string) {
    this.alertService.primary(message);
  }
  toastPrimary(message: string, title: string, smallText: string) {
    this.toastService.primary(message, title, smallText)
  }
  alertSecondary(message: string) {
    this.alertService.secondary(message);
  }
  toastSecondary(message: string, title: string, smallText: string) {
    this.toastService.secondary(message, title, smallText)
  }
  alertSuccess(message: string) {
    this.alertService.success(message);
  }
  toastSuccess(message: string, title: string, smallText: string) {
    this.toastService.success(message, title, smallText);
  }
  alertDanger(message: string) {
    this.alertService.danger(message);
  }
  toastDanger(message: string, title: string, smallText: string) {
    this.toastService.danger(message, title, smallText)
  }
  alertWarning(message: string) {
    this.alertService.warning(message);
  }
  toastWarning(message: string, title: string, smallText: string) {
    this.toastService.warning(message, title, smallText)
  }
  alertInfo(message: string) {
    this.alertService.info(message);
  }
  toastInfo(message: string, title: string, smallText: string) {
    this.toastService.info(message, title, smallText)
  }
  alertLight(message: string) {
    this.alertService.light(message);
  }
  toastLight(message: string, title: string, smallText: string) {
    this.toastService.light(message, title, smallText)
  }
  alertDark(message: string) {
    this.alertService.dark(message);
  }
  toastDark(message: string, title: string, smallText: string) {
    this.toastService.dark(message, title, smallText)
  }
  alertClear() {
    this.alertService.clear();
  }
  toastClear() {
    this.toastService.clear();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key !== 'Shift' && event.key !== 'Enter') {

      this.scannedBarcode += event.key;
      if (this.scannedBarcode.length === 8) {
        if (this.isCentre) {

          this.scannedBarcode = (isQwerty(this.scannedBarcode)) ? convertToAzerty(this.scannedBarcode) : this.scannedBarcode;
          this.scannedBarcode = this.scannedBarcode.toUpperCase();

          if (isCorrectProductSN(this.scannedBarcode)) {
            console.log('scanned barcode', this.scannedBarcode)

            if (!this.isAdmin) {
              const scanStatus: any = document.getElementById('scanStatus')
              scanStatus.innerHTML = 'Code-barre scanné : ' + this.scannedBarcode;

              this.addProductRetour(this.scannedBarcode);
            }
          } else {
            this.toastDanger('Le code-barre ne correspond pas à un code-barre d\'un Produit.', "Erreur", "Mauvais code-barre.")
          }
        } else {
          this.toastDanger("Vous n'avez pas la permission de scanner des codes-barres", "Erreur", "Non autorisé.")
        }

        this.scannedBarcode = '';
      } else if (this.scannedBarcode.length > 8) {
        this.toastWarning("Le code-barre n'est pas reconnu.", "Attention", 'Code-barre non reconnu.')
        this.scannedBarcode = '';
      }
    }
  }

  addProductRetour(barcode: string) {
    const productsWithSameBarcode = this.centreProducts.filter(pRetour => pRetour.barcode === barcode);
    if (productsWithSameBarcode.length > 0) {
      this.toastDanger("Le code-barre " + barcode + " a déjà été scanné.", "Erreur", "Déjà scanné.")
      return;
    }

    const productRetour = {
      idRetour: this.retour.id,
      barcode,
      scanDate: Math.trunc(Date.now() / 1000)
    };

    this.productRetourService.addProductRetour(productRetour, this.token)
      .subscribe({
        next: data => this.handleAddProductRetourResponse(data),
        error: err => this.handleResponseError(err),
        complete: () => console.log('product retour added!')
      });
  }

  handleResponseError(response: any) {
    console.error(response);
    this.toastDanger(response.error?.message ?? "Une erreur s'est produite.", 'Erreur', "Échec de l'action.");
  }

  handleAddProductRetourResponse(response: any) {
    console.log("RESPONSE", response)
    if (response.success) {
      console.log("ajout sur serveur succès!", response.process);

      this.centreProducts.push(response.product);
      this.toastSuccess("Produit " + response.product.barcode + " ajouté.", 'Succès', "Scan produit");

      if (this.centreProducts.length === 1)
        this.retourDetailComponent.startRetour();
    } else {
      this.toastDanger(response.error?.message ?? "Une erreur s'est produite.", 'Erreur', "Erreur serveur.");
    }

    const scanStatus: any = document.getElementById('scanStatus');
    scanStatus.innerHTML += '<br>' + 'En attente du scan';
  }

  removeProductRetour(product: any) {
    this.productRetourService.deleteProductRetour(this.retour.id, product, this.token)
      .subscribe({
        next: data => this.handleDeleteProductRetourResponse(data),
        error: err => this.handleResponseError(err),
        complete: () => console.log('product retour DELETED!')
      });
  }

  handleDeleteProductRetourResponse(response: any) {
    if (response.success) {
      this.centreProducts.splice(this.centreProducts.findIndex((p) => p.id === response.product.id), 1);
      this.toastSuccess("Le Produit " + response.product.barcode + " a bien été supprimé.", "Succès", "Suppression d'un produit");
    } else {
      this.toastDanger(response.error?.message ?? "Une erreur s'est produite.", 'Erreur', "Erreur serveur.");
    }

    const scanStatus: any = document.getElementById('scanStatus');
    scanStatus.innerHTML = 'En attente du scan';
  }
}

function isCorrectProductSN(barcodeStr: string) {
  const result = /^(([GKBCTF]){1,2}\d{7})$/.test(barcodeStr);
  return result;
}

function isQwerty(barcodeStr: string) {
  const re1 = '(.)';	// Any Single Character 1
  const re2 = '(\\d+)';	// Integer Number 1

  const p = new RegExp(re1 + re2, 'i');
  const m = p.exec(barcodeStr);
  return m === null;
}

function convertToAzerty(barcodeQwerty: string | any[]) {

  let convertStr = '';

  for (let i = 0; i < barcodeQwerty.length; i++) {

    switch (barcodeQwerty[i]) {
      case '&':
        convertStr += '1';
        break;
      case 'é':
        convertStr += '2';
        break;
      case '"':
        convertStr += '3';
        break;
      case '\'':
        convertStr += '4';
        break;
      case '(':
        convertStr += '5';
        break;
      case '§':
        convertStr += '6';
        break;
      case 'è':
        convertStr += '7';
        break;
      case '!':
        convertStr += '8';
        break;
      case 'ç':
        convertStr += '9';
        break;
      case 'à':
        convertStr += '0';
        break;
      case 'Q':
        convertStr += 'A';
        break;
      case 'W':
        convertStr += 'Z';
        break;
      case 'A':
        convertStr += 'Q';
        break;
      case 'Z':
        convertStr += 'W';
        break;
      case 'M':
        convertStr += '?';
        break;
      case '?':
        convertStr += 'M';
        break;
      default:
        convertStr += barcodeQwerty[i];
    }
  }

  return convertStr;
}
