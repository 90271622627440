import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paletteBarcode',
    standalone: false
})
export class PaletteBarcodePipe implements PipeTransform {

  transform(value: any, arg1: any): any {
    const serialNumber = value;
    // console.log('PaletteBarcodePipe | serialNumber', serialNumber);
    const provider = arg1;
    // console.log('PaletteBarcodePipe | provider', provider);

    const providerStr = provider.toString().padStart(2, '0');
    // console.log('PaletteBarcodePipe | providerStr', providerStr);
    const serialNumberStr = serialNumber.toString().padStart(6, '0');
    // console.log('PaletteBarcodePipe | serialNumberStr', serialNumberStr);

    const barcode = 'PA' + providerStr + serialNumberStr;
    // console.log('PaletteBarcodePipe | barcode', barcode);

    return barcode;
  }

}
