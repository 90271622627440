import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cartonBarcode',
    standalone: false
})
export class CartonBarcodePipe implements PipeTransform {

  transform(value: any, arg1: any): any {
    const serialNumber = value;
    // console.log('CartonBarcodePipe | serialNumber', serialNumber);
    const provider = arg1;
    // console.log('CartonBarcodePipe | provider', provider);

    const providerStr = provider.toString().padStart(2, '0');
    // console.log('CartonBarcodePipe | providerStr', providerStr);
    const serialNumberStr = serialNumber.toString().padStart(6, '0');
    // console.log('CartonBarcodePipe | serialNumberStr', serialNumberStr);

    const barcode = 'CA' + providerStr + serialNumberStr;
    // console.log('CartonBarcodePipe | barcode', barcode);

    return barcode;
  }

}
