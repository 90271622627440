import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-bordereau-livraison-basic',
    templateUrl: './bordereau-livraison-basic.component.html',
    styleUrls: ['./bordereau-livraison-basic.component.css'],
    standalone: false
})
export class BordereauLivraisonBasicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
