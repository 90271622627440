import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DemandeLivraisonBarcodePipe } from '../pipes/demande-livraison-barcode.pipe';
import { LivraisonBarcodePipe } from '../pipes/livraison-barcode.pipe';
import { AuthService } from '../services/auth.service';
import { DemandesLivraisonsService } from '../services/demandes-livraisons.service';
import { FranchiseService } from '../services/franchise.service';
import { LivraisonService } from '../services/livraison.service';
import { PaletteService } from '../services/palette.service';

@Component({
    selector: 'app-demandes-livraison-details',
    templateUrl: './demandes-livraison-details.component.html',
    styleUrls: ['./demandes-livraison-details.component.css'],
    standalone: false
})
export class DemandesLivraisonDetailsComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  userIsLoggedin: boolean = false;
  orderPalettes: any;
  errorHandled: any;
  error: any;
  palettes: any;
  paletteModeles: any;
  originalProvider: any;

  demandeLivraison: any;
  adresses: any[] = [];
  activeAdresses: any[] = [];
  demandeAddress: any = {};

  dateAjoutModelesPalette: string = '2020-10-21 12:18:59';

  isLivraisonPalettesOrderedReceived: boolean = false;

  constructor(private demandeLivraisonService: DemandesLivraisonsService,
              private franchiseService: FranchiseService,
              private livraisonService: LivraisonService,
              private paletteService: PaletteService,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
    }

    const id = this.activatedRoute.snapshot.params['id'];
    this.loadDemandeLivraison(id);
    this.loadAllPaletteModeles()
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadDemandeLivraison(id: number) {

    this.demandeLivraisonService.getDemandeLivraison(id, this.token)
      .subscribe(
        {
          next: data => this.handleDemandeLivraisonServerResponse(data),
          error: err => console.error(err),
          // complete: () => console.log('livraison loaded!')
        }
      );
  }

  loadFranchise(idPart: string) {
    this.franchiseService.getFranchiseByInitiales(idPart, this.token)
      .subscribe(
        {
          next: data => this.handleFranchise(data),
          error: err => console.error(err),
          // complete: () => console.log('livraison loaded!')
        }
      )
  }

  loadFranchiseAdresses(id: number) {
    this.franchiseService.getAllAdressesLivraisonByFranchise(id, this.token)
      .subscribe(
        {
          next: data => this.handleFranchiseAdressesServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('franchise adresses loaded!') 
        }
      )
  }

  handleDemandeLivraisonServerResponse(response: any) {
    // console.log("RESPONSE", response);
    if (response.success) {
      if(response.demandeLivraison) {
        this.demandeLivraison = response.demandeLivraison;
        if(this.demandeLivraison.address > 0) {
          this.loadLivraisonAdresse(this.demandeLivraison.address, this.demandeLivraison.franchise.id)
        } 
        // this.demandeLivraison.address = this.demandeAddress
        // console.log("DEMANDELIVRAISON ADDRESS", this.demandeLivraison)
        // this.loadLivraisonAdresse(this.demandeLivraison.address.id, this.demandeLivraison.franchise.id)
        this.loadFranchise(this.demandeLivraison.franchise.initiales);
        this.loadDemandesLivraisonPalettesOrdered(this.demandeLivraison.id)
        // console.log("this.demandeLivraison.address", this.demandeLivraison.address)
        this.loadFranchiseAdresses(this.demandeLivraison.franchise.id)
      }
    }
  }

  handleFranchise(response: any) {
    // console.log("RESPONSE FRANCHISE", response);
    if(response.success) {
      if(response.franchise) {
        this.demandeLivraison.franchise = response.franchise
        // this.demandeLivraison.address = this.demandeAddress
        // console.log("DEMANDE APRES FRANCHISE", this.demandeLivraison)
      }
    }
  }

  handleFranchiseAdressesServerResponse(response: any = {}) {
    this.adresses = response.adresses;
    // console.log("ADRESSES", this.adresses)

    for(let i = 0; i <= this.adresses.length - 1; i++) {
      if(this.adresses[i].isActif === 1) {
        this.activeAdresses.push(this.adresses[i])
      }
    } 
    // console.log('addresses', this.adresses)
  }

  loadDemandesLivraisonPalettesOrdered(id: number) {
    this.demandeLivraisonService.getDemandeLivraisonPalettesOrdered(id, this.token).subscribe(
      {
        next: (data: any) => this.handleLivraisonPalettesOrderedServerResponse(data),
        error: (err: any) => this.handleError(err),
        // complete: () => console.log('palettes ordered livraison loaded!')
      }
    );
  }

  handleLivraisonPalettesOrderedServerResponse(response: any = {}) {
    // console.log("RESPONSE PALETTE ORDER", response)
    if (response.success) {
      if (response.orderPalettes) {
        if (this.demandeLivraison) {
          this.orderPalettes = response.orderPalettes;
          this.isLivraisonPalettesOrderedReceived = true;

          if (this.demandeLivraison.creationDatetime > this.dateAjoutModelesPalette) {
            this.recalculateNbPalettes();
          }
          /*if (this.livraison.status >= 1
            && this.livraison.status !== 6
            && this.isLivraisonReceived
            && this.isLivraisonPalettesOrderedReceived) {
              this.loadBonLivraison();
          }*/
        }
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  onChangeAddress(newAddress: number) {

    const address = this.adresses.find((a: { id: number }) => a.id === newAddress);
    // console.log('address', address)
    this.demandeLivraison.address = address
    if(this.demandeLivraison.address.accessibilite_19T) {
      this.loadLivraisonAdresse(this.demandeLivraison.address.id, this.demandeLivraison.franchise.id)
    } else {
      this.loadFacturationAdresse(this.demandeLivraison.address.id, this.demandeLivraison.franchise.id)
    }

    if (this.demandeLivraison.statut === 3) {
      this.modifyAdresseLivraison()
    } else {
      this.modifyLivraison();
    }
  }

  modifyAdresseLivraison() {
    this.demandeLivraisonService.updateAdresseLivraison(this.demandeLivraison.id, this.demandeLivraison, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateLivraisonServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('livraison address was updated!')
        }
      )
  }

  modifyLivraison() {
    // console.log('livraison address selected', this.demandeLivraison.address);

    this.demandeLivraisonService.updateDemande(this.demandeLivraison.id, this.demandeLivraison, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateLivraisonServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('livraison was updated!')
        }
      );
  }

  loadLivraisonAdresse(id_address: number, id_franchise: number) {
    // console.log("load livraison address")
    this.franchiseService.getAdresseLivraisonByFranchise(id_address, id_franchise, this.token)
      .subscribe(
        {
          next: data => this.handleFranchiseAddress(data),
          error: err => this.handleError(err),
          // complete: () => console.log('livraison adresse loaded')
        }
      )
  }

  loadFacturationAdresse(id_address: number, id_franchise: number) {
    // console.log("load facturation address")
    this.franchiseService.getAdresseLivraisonByFranchise(id_address, id_franchise, this.token)
      .subscribe(
        {
          next: data => this.handleFranchiseAddress(data),
          error: err => this.handleError(err),
          // complete: () => console.log('livraison adresse loaded')
        }
      )
  }

  handleFranchiseAddress(response: any = {}) {
    // console.log('response handle franchise address', response)
    if(response.success) {
      // console.log('response livraison address', response.address[0])
      this.demandeAddress = response.address[0]
      this.demandeLivraison.address = this.demandeAddress
    }
  }

  putOrderPalettes(data: any) {
    this.demandeLivraisonService.putOrderPalettes(this.demandeLivraison.id, data, this.token)
      .subscribe(
        {
          next: (data: any) => this.handlePutOrderPalettesServerResponse(data),
          error: (err: any) => this.handleError(err),
          // complete: () => console.log('order palettes loaded!')
        }
      );
  }

  handlePutOrderPalettesServerResponse(response: any = {}) {

    if (response.success) {
      // console.log('RESPONSE PALETTE', response)
      this.recalculateNbPalettes();
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  deleteOrderPalettes(modelePalette: any) {
    this.demandeLivraisonService.deleteOrderPalettes(this.demandeLivraison.id, modelePalette, this.token)
      .subscribe(
        {
          next: (data: any) => this.handleDeleteOrderPalettesServerResponse(data),
          error: (err: any) => this.handleError(err),
          // complete: () => console.log('delete order palette loaded!')
        }
      );
  }

  handleDeleteOrderPalettesServerResponse(response: any = {}) {

    if (response.success) {
      this.recalculateNbPalettes();
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  removePalette(event: any) {
    var parentElementActiveRow = event.srcElement.parentElement.parentElement;
    var activeRow = parentElementActiveRow.children[0].children[0].children;

    var elemNbPalette = this.getNbPaletteElementX(activeRow, true);
    var nbPalettes = elemNbPalette.value;

    var modelePaletteValue = this.getTypePaletteValueX(activeRow, true);

    var findedObj: any = this.orderPalettes.find((m: { modelePalette: number; }) => m.modelePalette === modelePaletteValue);
    findedObj.nbPalettes -= nbPalettes;

    if (findedObj.nbPalettes === 0) {
      this.orderPalettes = this.orderPalettes.filter((m: { modelePalette: number; }) => m.modelePalette !== modelePaletteValue);

      this.deleteOrderPalettes(findedObj.modelePalette);
    }

    parentElementActiveRow.remove();

    /*var dataToPatch = [
      { "op": "remove", "modelePalette": modelePaletteValue }
    ];

    this.patchDataPalette(dataToPatch);*/
  }

  validatePalette(event: any) {
    // console.log("event validatePalette")
    var activeRow = event.srcElement.parentElement.parentElement.children[0].children[0].children;
    // console.log("activeRow", activeRow);
    var self = this;

    this.getNbPaletteElement(activeRow, (elemNbPalette: any = {}) => {
      var nbPalettes = elemNbPalette.value;
      // console.log('nbPalettes', nbPalettes);
      self.getTypePaletteValue(activeRow, (modelePaletteValue: any) => {

        var obj = { modelePalette: modelePaletteValue, nbPalettes: Number(nbPalettes) }
        // console.log("obj", obj)
        var findedObj: any = self.orderPalettes.find((m: { modelePalette: any; }) => m.modelePalette === modelePaletteValue);
        // console.log("findedObj", findedObj)
        if (typeof findedObj === "undefined") {
          self.orderPalettes.push(obj);
        } else {
          findedObj.nbPalettes += Number(nbPalettes);
        }

        self.putOrderPalettes(obj);
      });
    });
  }

  recalculateNbPalettes() {
    this.demandeLivraison.nbPalettes = 0
  
    for(let p of this.orderPalettes) {
      this.demandeLivraison.nbPalettes += p.nbPalettes;
    }
  }

  handleError(error: any = {}) {
    console.error(error);
    this.error = error;
  }

  loadAllPaletteModeles() {
    this.paletteService.getPaletteModeles(this.token)
      .subscribe(
        {
          next: data => this.handlePaletteModelesServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('palette model loaded!')
        }
      );
  }

  handlePaletteModelesServerResponse(response: any = {}) {

    if (response.success) {
      this.paletteModeles = response.paletteModeles.sort((a: { id: number; }, b: { id: number; }) => {
        return a.id - b.id;
      });
      // console.log('this.paletteModeles', this.paletteModeles)

    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  paletteChanged(event: any) {
    var self = this;

    var value = event.target.value;
    var form = event.srcElement.form;
    var elementParent = event.srcElement.parentElement;
    var formBox = elementParent.children;
    var buttonBox = elementParent.parentElement.parentElement.lastChild;

    var buttonMinus = formBox[2].firstChild;
    var nbPaletteInput = formBox[3].firstChild;
    var buttonPlus = formBox[4].firstChild;

    for(var i = 0, len = form.length; i < len; i++) {

      if (form[i].getAttribute('id') === 'addPaletteButton') {

        switch (value) {
          case '1': // Cassettes
          case '2': // Personnalisée
          case '3': // 3 produits [P3P]
          case '4': // 4 produits [P4P]
          case '7': // 3 produits B [P3PB]
          case '8': // Baguettes [PBG]
          case '9': // Canettes [PCN]
          case '10': // Fusettes [PFU]
            buttonMinus.removeAttribute('disabled');
            nbPaletteInput.removeAttribute('disabled');
            buttonPlus.removeAttribute('disabled');
            buttonBox.style.visibility = 'visible';
            break;
          default:
            buttonMinus.setAttribute('disabled', '');
            nbPaletteInput.value = "0";
            nbPaletteInput.setAttribute('disabled', '');
            buttonPlus.setAttribute('disabled', '');
            buttonBox.style.visibility = 'hidden';
            return 'Inconnu';
        }

        this.getNbPaletteElement(formBox, function(elemNbPalette: any) {
          var nbPalettes = Number(elemNbPalette.value);

          self.toogleValidateButton(value, nbPalettes);
          /*if (Number.isNaN(Number(value)) && Number(elemNbPalette.value) > 0) {
            form[i].setAttribute('disabled', true);
          } else {
            form[i].removeAttribute('disabled');
          }*/
        });
      }
    }
    return;
  }

  getNbPaletteElement(activeRow: any, callback: any) {
    for(var i = 0; i< activeRow.length;i++) {
      var element = activeRow[i];

      if (element.getAttribute('id') === 'nbPalettes') {
        callback(element.children[0]);
      }
    }
  }

  toogleValidateButton(modelePaletteValue: any, nbPalettes: number) {
    // console.log("toogleValidateButton ")

    var addPaletteButton: any = document.getElementById('addPaletteButton');

    if (!Number.isNaN(Number(modelePaletteValue)) && Number(nbPalettes) > 0) {
      // console.log('ACTIVATE BUTTON');
      addPaletteButton.removeAttribute('disabled');
    } else {
      // console.log('DISABLE BUTTON');
      addPaletteButton.setAttribute('disabled', '');
    }
  }

  nbPaletteInputed(event: any) {
    // console.log("nbPaletteInputed event console 1")
    var self = this;

    if (event.data !== null) {
      if (isNaN(event.data)) {
        // console.log("nbPaletteInputed event console 2")

        event.stopPropagation();
      }

      var activeFormRow: any = document.getElementById('active-form-row');
      // console.log("nbPaletteInputed event console 3")
      // console.log('nbPaletteInputed | activeFormRow', activeFormRow);

      var formBox = activeFormRow.children[0].children[0].children;
      // console.log('nbPaletteInputed | formBox', formBox);
      // console.log("nbPaletteInputed event console 4")

      var elemNbPalette = this.getNbPaletteElementX(formBox, false);
      // console.log("nbPaletteInputed event console 5")

      var typePalette = this.getTypePaletteValueX(formBox, false);
      // console.log("nbPaletteInputed event console 6")

      this.toogleValidateButton(typePalette, Number(elemNbPalette.value));
    }
  }

  getNbPaletteElementX(activeRow: any, isValidate: any) {
    var element = (isValidate) ? activeRow[2] : activeRow[3];
    var subElement = element.firstChild;
    return subElement;
  }

  getTypePaletteValueX(activeRow: any, isValidate: boolean) {
    var element = activeRow[1];
    var modelePaletteValue = parseInt(element.options[element.selectedIndex].value, 10);
    return modelePaletteValue;
  };

  minusClicked(event: any) {
    var activeRow = event.srcElement.parentElement.parentElement.children;
    var self = this;

    this.getNbPaletteElement(activeRow, function(elemNbPalette: any) {
      var nbPalettes = elemNbPalette.value;
      if (nbPalettes > 0) {
        nbPalettes = Number(nbPalettes) - 1;
        elemNbPalette.value = nbPalettes;
      }

      self.getTypePaletteValue(activeRow, function(modelePaletteValue: any) {
        self.toogleValidateButton(modelePaletteValue, nbPalettes);
      });
    });
  }

  getTypePaletteValue(activeRow: any, callback: any) {
    for(var i = 0; i< activeRow.length; i++) {
      var element = activeRow[i];

      if (element.getAttribute('id') === 'inputGroupSelectPalette') {
        var modelePaletteValue = parseInt(element.options[element.selectedIndex].value, 10);
        callback(modelePaletteValue);
      }
    }
  };

  plusClicked(event: any) {
    var activeRow = event.srcElement.parentElement.parentElement.children;
    var self = this;

    this.getNbPaletteElement(activeRow, function(elemNbPalette: any) {
      var nbPalettes = elemNbPalette.value;
      nbPalettes = Number(nbPalettes) + 1;
      elemNbPalette.value = nbPalettes;

      self.getTypePaletteValue(activeRow, function(modelePaletteValue: any) {
        self.toogleValidateButton(modelePaletteValue, nbPalettes);
      });
    });
  }

  validateLivraison(event: any) {
    this.recalculateNbPalettes();
    this.demandeLivraison.statut = this.demandeLivraison.statut + 1;
    this.demandeLivraison.palettes = this.orderPalettes
    // console.log('livraison validated', this.demandeLivraison)

    this.demandeLivraisonService.updateDemande(this.demandeLivraison.id, this.demandeLivraison, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateLivraisonServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('livraison was updated!')
        }
      );
  }

  handleUpdateLivraisonServerResponse(response: any = {}) {

    if (response.success) {
      if (response.livraison) {
        this.demandeLivraison = response.livraison;
        this.demandeLivraison.palettes = this.palettes;
        // console.log('livraison updated', this.demandeLivraison)
        //this.loadCartonsFromLivraison(this.demandeLivraison.id)
        this.demandeLivraison.barcode = new DemandeLivraisonBarcodePipe()
          .transform(this.demandeLivraison.serialNumber, this.demandeLivraison.franchise.id);

        this.originalProvider = Object.assign({}, this.demandeLivraison.franchise);
        //this.generatePdfBonLivraison(this.livraison)
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

}
