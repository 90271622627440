import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'retourStatus',
    standalone: false
})
export class RetourStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 0:
        return 'En cours de création';
      case 1:
        return 'Retours attendus';
      case 2:
        return 'En cours de scan';
      case 3:
        return 'Retours scannés';
      default:
        return 'Inconnu';
    }
  }

}
