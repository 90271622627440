<ngb-datepicker #d 
                (dateSelect)="onDateSelection($event)" 
								[startDate]="{ year: fromDate?.year || today.year, month: fromDate?.month || today.month }"
                [displayMonths]="1" 
                [dayTemplate]="t" 
                outsideDays="hidden" 
                [navigation]="'select'">
</ngb-datepicker>

<ng-template #t let-date let-focused="focused">
	<span
		class="custom-day"
		[class.focused]="focused"
		[class.range]="isRange(date)"
		[class.faded]="isHovered(date) || isInside(date)"
		(mouseenter)="hoveredDate = date"
		(mouseleave)="hoveredDate = null"
	>
		{{ date.day }}
	</span>
</ng-template>

<br><br>

<!--<div *ngIf="fromDate">Date de début : {{ fromDate.day }}/{{ fromDate.month }}/{{ fromDate.year }}</div>
<div *ngIf="toDate">Date de fin : {{ toDate.day }}/{{ toDate.month }}/{{ toDate.year }}</div>

<br><br>-->

<div *ngIf="fromDateJs">Date de début : {{ fromDateJs | date: 'dd/MM/yyyy' }}</div>
<div *ngIf="toDateJs">Date de fin : {{ toDateJs | date: 'dd/MM/yyyy' }}</div>

<hr />

<div *ngIf="productsMaked">
	<h3>Fabrication de <i>Filtres Stabilisateurs©</i></h3>
	<table class="table table-bordered">
		<thead>
			<tr>
				<th scope="col">Cassettes</th>
				<th scope="col">Canettes</th>
				<th scope="col">Baguettes</th>
				<th scope="col">Fusettes</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>{{ productsMaked.nbCassettes }} cassette
					<ng-container *ngIf="productsMaked.nbCassettes > 0">
						(≃ {{ (productsMaked.nbCassettes / 672) | number:'1.0-2' }} palettes) 
						(≃ {{ (productsMaked.nbCassettes / 14) | number:'1.0-2' }} cartons)
					</ng-container>
				</td>
				<td>{{ productsMaked.nbCanettes }} canette
					<ng-container *ngIf="productsMaked.nbCanettes > 0">
						(≃ {{ (productsMaked.nbCanettes / 24) | number:'1.0-2' }} cartons)
					</ng-container>
				</td>
				<td>{{ productsMaked.nbBaguettes }} baguette
					<ng-container *ngIf="productsMaked.nbBaguettes > 0">
						(≃ {{ (productsMaked.nbBaguettes / 24) | number:'1.0-2' }} cartons)
					</ng-container>
				</td>
				<td>{{ productsMaked.nbFusettes }} fusette
					<ng-container *ngIf="productsMaked.nbFusettes > 0">
						(≃ {{ (productsMaked.nbFusettes / 42) | number:'1.0-2' }} cartons)
					</ng-container>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<div *ngIf="cartonsMaked">
	<h3>Fabrication de Cartons</h3>
	<table class="table table-bordered">
		<thead>
			<tr>
				<th scope="col">Cartons 📦 Cassettes</th>
				<th scope="col">Cartons 📦 Canettes</th>
				<th scope="col">Cartons 📦 Baguettes</th>
				<th scope="col">Cartons 📦 Fusettes</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>{{ cartonsMaked.nbCartonsK7 }}</td>
				<td>{{ cartonsMaked.nbCartonsCanette }}</td>
				<td>{{ cartonsMaked.nbCartonsBaguette }}</td>
				<td>{{ cartonsMaked.nbCartonsFusette }}</td>
			</tr>
		</tbody>
	</table>
</div>

<div *ngIf="palettesMaked">
	<h3>Fabrication de Palettes</h3>
	<table class="table table-bordered">
		<thead>
			<tr>
				<th scope="col">Palettes Cassettes</th>
				<th scope="col">Palettes 3 produits [P3P]</th>
				<th scope="col">Palettes 3 produits B [P3PB]</th>
				<th scope="col">Palettes Canettes</th>
				<th scope="col">Palettes Baguettes</th>
				<th scope="col">Palettes Fusettes</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>{{ palettesMaked.nbPalettesK7 }}</td>
				<td>{{ palettesMaked.nbPalettesP3P }}</td>
				<td>{{ palettesMaked.nbPalettesP3PB }}</td>
				<td>{{ palettesMaked.nbPalettesCanette }}</td>
				<td>{{ palettesMaked.nbPalettesBaguette }}</td>
				<td>{{ palettesMaked.nbPalettesFusette }}</td>
			</tr>
		</tbody>
	</table>
</div>

<div *ngIf="productsRetours">
	<h3>Retour de <i>Filtres Stabilisateurs©</i> scannés</h3>
	<table class="table table-bordered">
		<thead>
			<tr>
				<th scope="col">Cassettes</th>
				<th scope="col">Canettes</th>
				<th scope="col">Baguettes</th>
				<th scope="col">Fusettes</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>{{ productsRetours.nbCassettes }} 
					<ng-container *ngIf="productsRetours.nbCassettes > 0">
						(≃ {{ (productsRetours.nbCassettes / 672) | number:'1.0-2' }} palettes)
					</ng-container>
				</td>
				<td>{{ productsRetours.nbCanettes }}</td>
				<td>{{ productsRetours.nbBaguettes }}</td>
				<td>{{ productsRetours.nbFusettes }}</td>
			</tr>
		</tbody>
	</table>
</div>

<!--<pre *ngIf="productsMaked">{{ productsMaked | json }} </pre>
<hr />
<pre *ngIf="cartonsMaked">{{ cartonsMaked | json }} </pre>
<hr />
<pre *ngIf="palettesMaked">{{ palettesMaked | json }} </pre>
<hr />
<pre *ngIf="productsRetours">{{ productsRetours | json }} </pre>-->

<!--<pre>From: {{ fromDate | json }} </pre>
<pre>To: {{ toDate | json }} </pre>-->