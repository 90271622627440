import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cartonStatus',
    standalone: false
})
export class CartonStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch(value) {
      case 0:
        return 'En cours de création';
      case 1:
        return 'Prêt à être palettisé';
      case 2:
        return 'Palettisé';
      default:
        return 'Inconnu';
    }
  }

}
