import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'retourBarcode',
    standalone: false
})
export class RetourBarcodePipe implements PipeTransform {

  transform(serialNumber: number, provider: any): any {
    const providerStr = provider.toString().padStart(2, '0');
    const serialNumberStr = serialNumber.toString().padStart(6, '0');
    return 'RE' + providerStr + serialNumberStr;
  }

}
