<div *ngIf="!userIsLoggedin">
	<form class="form-signin" #loginForm="ngForm" (ngSubmit)="login(loginForm.value)">
		<div id="login-form">
			<h2 class="form-signin-heading">Connexion</h2>
	
			<label for="inputId" class="visually-hidden">Identifiant</label>
			<input type="text" id="inputId" class="form-control" ngModel name="username" placeholder="Identifiant" required autofocus>

			<label for="inputPassword" class="visually-hidden">Mot de passe</label>
			<input type="password" id="inputPassword" class="form-control" ngModel name="password" placeholder="Mot de passe" required>
	
			<div class="d-grid gap-2">
				<button class="btn btn-lg btn-primary btn-primary-bcp" type="submit" [disabled]="loginForm.invalid">Se connecter</button>
			</div>
		</div>
	</form>
</div>

<ng-container *ngIf="userIsLoggedin && stats">
	<ul class="list-group">
		<li class="list-group-item d-flex justify-content-between align-items-center">
			Total Livraisons
			<span class="badge bg-primary rounded-pill">{{stats['TotalLivraisons']}}</span>
		</li>
		<li class="list-group-item d-flex justify-content-between align-items-center">
			Total Palettes
			<span class="badge bg-primary rounded-pill">{{stats['TotalPalettes']}}</span>
		</li>
		<li class="list-group-item d-flex justify-content-between align-items-center">
			Total Cartons
			<span class="badge bg-primary rounded-pill">{{stats['TotalCartons']}}</span>
		</li>
		<li class="list-group-item d-flex justify-content-between align-items-center">
			Total Filtres Stabilisateurs©
			<span class="badge bg-primary rounded-pill">{{stats['TotalProducts']}}</span>
		</li>
	</ul>
</ng-container>
  
<ng-container *ngIf="userIsLoggedin && !isAdmin">
	<br>

	<div style="display: block">
		<div class="row row-cols-3">
			<div class="col-1 vertical-center" id="previous-week">
				<button appNoDblClick type="button" class="btn btn-link button-week" (click)="previousWeek($event)">
					<svg width="3em" height="3em" viewBox="0 0 16 16" class="bi bi-caret-left-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
					</svg>
				</button>
			</div>
			<div class="col-10">
				<canvas #chartProducts baseChart
					[data]="barChartData"
					[options]="barChartOptions"
					[labels]="barChartLabels"
					[type]="'bar'"
					[legend]="barChartLegend">
				</canvas>
				
			</div>
			<div class="col-1 vertical-center" id="next-week">
				<button appNoDblClick #nextWeekBtn type="button" id="next-week-btn" class="btn btn-link button-week" (click)="nextWeek($event)">
					<svg width="3em" height="3em" viewBox="0 0 16 16" class="bi bi-caret-right-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
					</svg>
				</button>
			</div>
		</div>
		
	</div>
</ng-container>