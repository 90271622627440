import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ProviderService } from '../services/provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Provider } from '../model/provider';

@Component({
    selector: 'app-providers-details',
    templateUrl: './providers-details.component.html',
    styleUrls: ['./providers-details.component.css'],
    standalone: false
})
export class ProvidersDetailsComponent {
  id: any;

  originalProvider: any = {
    id: 0,
    nom: ''
  };

  provider: any = { ...this.originalProvider };
  
  addresses: any = [];
  contacts: any = [];

  token: string = '';
  decodedToken: any = {};
  userIsLoggedin: boolean = false;

  provider_updated: string = '';
  address_updated: string = '';
  contact_updated: string = '';

  selectedAddress: any = {
    id: 0,
    nom: '',
    adresse: '',
    complement_adresse: '',
    code_postale: '',
    ville: '',
    latitude: '',
    longitude: '',
    telephone: '',
    defaut: false
  };

  selectedContact: any = {
    id: 0,
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    defaut: false
  };

  formNewAddress: boolean = false;
  formNewContact: boolean = false;

  removeDefaultAddressError: boolean = false;
  removeDefaultContactError: boolean = false;

  constructor(private providerService: ProviderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);

      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.id = this.activatedRoute.snapshot.params['id'];
        this.loadProvider(this.id);
      } else {
        this.router.navigateByUrl('/');
      }
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadProvider(id: any) {
    this.providerService.getProvider(id, this.token).subscribe(
      {
        next: data => this.displayProvider(data),
        error: err => console.error(err),
        complete: () => console.log('provider loaded!')
      }
    )

    this.providerService.getProviderAddresses(id, this.token).subscribe({
      next: data => this.displayAddresses(data),
      error: err => console.error(err),
      complete: () => console.log('provider adresses loaded!')
    });

    this.providerService.getProviderContacts(id, this.token).subscribe({
      next: data => this.displayContact(data),
      error: err => console.error(err),
      complete: () => console.log('provider contacts loaded!')
    });
  }

  displayContact(response: any) {
    this.contacts = [...response.contacts];
  }

  displayAddresses(response: any) {
    this.addresses = [...response.addresses];
  }

  displayProvider(response: any) {
    this.provider = { ...this.originalProvider, ...response.provider };
    this.originalProvider = { ...this.originalProvider, ...response.provider };
    
    if( this.provider.adresse)
      this.selectedAddress = { ...this.provider.adresse };
    
    if( this.provider.contact)
      this.selectedContact = { ...this.provider.contact };
    
    console.log("this.provider", this.provider);
  }

  onUpdateProvider(id: any, value: any) {
    if (id === 0) return;

    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    console.log(value);

    this.providerService.updateProvider(id, value, token).subscribe(
      {
        next: data => this.handleUpdateProvider(data),
        error: err => console.error(err),
        complete: () => console.log('provider was updated!')
      }
    )
  }

  handleUpdateProvider(response: any) {
    this.provider_updated = "Données mises à jour.";
    this.displayProvider(response);
  }

  addAddress(value: any) {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    if (this.addresses.length === 0)
      value.defaut = true;

    this.providerService.addAddress(this.id, value, token).subscribe(
      {
        next: data => this.handleAddAddress(data),
        error: err => console.error(err),
        complete: () => console.log('address was added!')
      }
    )
  }

  handleAddAddress(response: any) {
    window.location.reload();
    console.log("ADDED PROVIDER ADDRESS", response);
  }

  onChangeProviderAddress(newAddress: number) {
    console.log('newAddress', newAddress)
    const address = this.addresses.find((a: { id: number }) => a.id === newAddress);
    console.log('address', address)
    this.selectedAddress = address;
    this.removeDefaultAddressError = false;
    // this.franchise.faddress = address
    console.log('this.id', this.id)
    this.loadAddress(this.id, address.id);
  }

  loadAddress(idProvider: number, idAddress: number) {
    this.providerService.getAddress(idProvider, idAddress, this.token)
      .subscribe(
        {
          next: data => this.handleAddress(data),
          error: err => console.log(err),
          complete: () => console.log('address loaded')
        }
      )
  }

  handleAddress(response: any = {}) {
    console.log('response handle franchise address', response)
    if (response.success) {
      console.log('response livraison address', response.address)
      this.selectedAddress = response.address;
      console.log('this.selectedAddress', this.selectedAddress)
    }
  }

  updateAddress(idAddress: any, value: any) {
    if (idAddress === 0) return;

    console.log("value", value);
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    this.providerService.updateAddress(this.id, idAddress, value, token).subscribe(
      {
        next: data => this.handleUpdateProviderAddress(data),
        error: err => console.error(err),
        complete: () => console.log('address was updated!')
      }
    )
  }

  handleUpdateProviderAddress(response: any) {
    this.address_updated = "Données mises à jour."
  }

  onChangeDefaultAddress(event: any) {
    if (this.selectedAddress.id === this.originalProvider.adresse?.id) {
      event.preventDefault();
      event.target.checked = true;
      this.selectedAddress.defaut = true;

      this.removeDefaultAddressError = true;
    }
  }

  addContact(value: any) {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    if (this.contacts.length === 0)
      value.defaut = true;

    this.providerService.addContact(this.id, value, token).subscribe(
      {
        next: data => this.handleAddContact(data),
        error: err => console.error(err),
        complete: () => console.log('contact was added!')
      }
    )
  }

  handleAddContact(response: any) {
    window.location.reload();
    console.log("ADDED PROVIDER CONTACT", response);
  }

  onChangeProviderContact(newContact: number) {
    console.log('newContact', newContact)
    const contact = this.contacts.find((c: { id: number }) => c.id === newContact);
    console.log('contact', contact)
    this.selectedContact = contact;
    this.removeDefaultContactError = false;
    console.log('this.id', this.id)
    this.loadContact(this.id, contact.id);
  }

  loadContact(idProvider: number, idContact: number) {
    this.providerService.getContact(idProvider, idContact, this.token)
      .subscribe(
        {
          next: data => this.handleContact(data),
          error: err => console.log(err),
          complete: () => console.log('contact loaded')
        }
      )
  }

  handleContact(response: any = {}) {
    console.log('response handle provider contact', response)
    if (response.success) {
      console.log('response provider contact', response.contact)
      this.selectedContact = response.contact;
      console.log('this.selectedContact', this.selectedContact)
    }
  }

  updateContact(idContact: any, value: any) {
    if (idContact === 0) return;

    console.log("value", value);
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    this.providerService.updateContact(this.id, idContact, value, token).subscribe(
      {
        next: data => this.handleUpdateProviderContact(data),
        error: err => console.error(err),
        complete: () => console.log('contact was updated!')
      }
    )
  }

  handleUpdateProviderContact(response: any) {
    this.contact_updated = "Données mises à jour."
  }

  onChangeDefaultContact(event: any) {
    if (this.selectedContact.id === this.originalProvider.contact?.id) {
      event.preventDefault();
      event.target.checked = true;
      this.selectedContact.defaut = true;

      this.removeDefaultContactError = true;
    }
  }
}
