import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css'],
    standalone: false
})
export class UserProfileComponent implements OnInit {

  decodedToken: any = {};
  isAdmin: boolean = false;
  userEmail: string = '';
  jobs: [] = [];
  adsTitle: string = '';

  constructor(private authService: AuthService) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    if (this.authService.userIsLoggedIn()) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.decodedToken = this.authService.decodeToken(bcpToken.token);
      // console.log(this.decodedToken);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      this.userEmail = this.decodedToken.email;
      // admin needs to see all ads
      /*if(this.isAdmin) {
        this.loadAdsWithoutFilter();
      } else {
        this.loadAds(this.userEmail);
      }*/
    }
  }

  /*loadAds(userEmail) {
    this.jobService.getJobsByUserEmail(userEmail)
                    .subscribe(
                      data => this.displayAds(data.jobs),
                      err => console.error(err)
                    )
  }

  loadAdsWithoutFilter() {
    this.jobService.getJobs()
                    .subscribe(
                      data => this.displayAds(data), // directly return an array
                      err => console.error(err)
                    )
  }*/

  /*displayAds(jobs) {
    console.log('jobs ', jobs);
    this.jobs = jobs;
    switch(this.jobs.length) {
      case 0:
        this.adsTitle = 'Aucune annonce postée à ce jour';
        return;
      case 1:
        this.adsTitle = '1 annonce postée';
        return;
      default:
        this.adsTitle = `${this.jobs.length} annonces postées`;
    }
  }*/

}
