import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Provider} from '../model/provider';
import {FranchiseService} from '../services/franchise.service';
import {Retour} from '../model/retour';
import {RetourService} from '../services/retour.service';
import {ProductService} from '../services/product.service';
import {AuthService} from '../services/auth.service';
import {Franchise} from '../model/franchise';
import {ProviderService} from '../services/provider.service';
import {RetourBarcodePipe} from '../pipes/retour-barcode.pipe';
import {ActivatedRoute} from '@angular/router';
import {CartonRetour} from '../model/carton_retour';
import { ProductRetour } from '../model/product_retour';
import { ScanCartonsComponent } from '../scan-cartons/scan-cartons.component';
import { ScanCartonsRetourComponent } from '../scan-cartons-retour/scan-cartons-retour.component';
import { PaletteService } from '../services/palette.service';
import { LivraisonStatusPipe } from '../pipes/livraison-status.pipe';
import { LivraisonService } from '../services/livraison.service';
import { LivraisonBarcodePipe } from '../pipes/livraison-barcode.pipe';

@Component({
    selector: 'app-retour-details',
    templateUrl: './retour-details.component.html',
    styleUrls: ['./retour-details.component.css'],
    providers: [ScanCartonsRetourComponent],
    standalone: false
})
export class RetourDetailsComponent implements OnInit, AfterViewChecked {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  isFranchise: boolean = false;
  isCentre: boolean = false;
  userIsLoggedin: boolean = false;
  products: any;

  retour: any;
  retourFranchise: any;
  retourCentre: any;
  originalProvider: any;
  providers: any;
  franchises: any;
  productsFranchise: ProductRetour[] = [];
  productsCentre: ProductRetour[] = [];
  error: any;
  errorHandled: any;
  source: string = '';
  parametersObservable: any;

  // associatedLivraison: any;

  paletteModeles: any;
  orderPalettes: any;

  isRetourReceived: boolean = false;
  isRetourPalettesOrderedReceived: boolean = false;

  demandeRetourPdfBase64: any;
  blobDemandeRetour: any;

  associatedAddress: any;
  addresses: any[] = [];

  constructor(private retourService: RetourService,
              private paletteService: PaletteService,
              private productService: ProductService,
              private providerService: ProviderService,
              private franchiseService: FranchiseService,
              private livraisonService: LivraisonService,
              private activatedRoute: ActivatedRoute,
              private ref: ChangeDetectorRef,
              private authService: AuthService) {
  }
  ngOnInit() {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      if (this.decodedToken && this.decodedToken.role.nom === 'franchise') {
        this.isFranchise = true;
      }
      if (this.decodedToken && this.decodedToken.role.nom === 'centre') {
        this.isCentre = true;
      }
    }

    const id = this.activatedRoute.snapshot.params['id'];
    this.loadRetour(id);
  }

  ngAfterViewChecked(): void {
    // explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
    this.ref.detectChanges();
  }

  loadRetour(id: number) {
    this.retourService.getRetour(id, this.token)
      .subscribe(
        {
          next: data => this.handleServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('retour loaded!')
        }
      );
  }

  loadRetourSource(id: number, source: string) {
    this.retourService.getRetourSource(id, source, this.token)
      .subscribe(
        {
          next: data => this.handleRetourSourceServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('retour source loaded!')
        }
      );
  }

  loadCartonsRetourSource(id: number, source: string) {
    this.retourService.getCartonsFromRetour(id, source, this.token)
      .subscribe(
        {
          next: data => this.handleCartonsServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('cartons from retour loaded!')
        }
      );
  }

  loadProductsRetourSource(id: number, source: string) {
    this.retourService.getProductsFromRetour(id, source, this.token)
      .subscribe(
        {
          next: data => this.handleProductsServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('products from retour loaded!')
        }
      );
  }

  loadAllProviders() {
    this.providerService.getProviders(this.token)
      .subscribe(
        {
          next: data => this.handleProviderServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('providers loaded!')
        }
      );
  }

  loadAllFranchises() {
    this.franchiseService.getFranchises(this.token)
      .subscribe(
        {
          next: data => this.handleFranchiseServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('franchises loaded!')
        }
      );
  }

  addRetourSource(source: string) {
    this.retourService.addRetourSource(this.retour.id, source, this.token)
      .subscribe(
        {
          next: data => this.handleAddRetourSourceServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('retour loaded!')
        }
      );
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  handleServerResponse(response: any = {}) {
    if (response.success) {
      if (response.retour) {
        this.retour = response.retour;

        // console.log("retour is:", this.retour);
        
        this.retour.barcode = new RetourBarcodePipe()
          .transform(this.retour.serialNumber, this.retour.provider.id);

        this.originalProvider = Object.assign({}, this.retour.provider);

        if (!this.retour.franchise) {
          this.retour.franchise = <Franchise>{
            id: 0,
            initiales: ''
          };
        }

        /*if (this.retour.associated_livraison) {
          this.loadLivraison(this.retour.associated_livraison);
        }*/

        this.loadFranchiseAdresses(this.retour.id_franchise);

        this.isRetourReceived = true;
      }

      if (this.isRetourReceived) {
        if (this.isAdmin) {
          this.loadRetourSource(this.retour.id, 'centre');
          this.loadRetourSource(this.retour.id, 'franchise');
        } else if (this.isFranchise) {
          this.loadRetourSource(this.retour.id, 'franchise');
        } else if (this.isCentre) {
          this.loadRetourSource(this.retour.id, 'centre');
        }

        if (this.retour.status === 0) {
          if (this.providers === undefined) {
            this.loadAllProviders();
          }

          if (this.franchises === undefined) {
            this.loadAllFranchises();
          }

          if (this.paletteModeles === undefined) {
            this.loadAllPaletteModeles();
          }
        } else {
          // console.log("in handleServerResponse : " + this.retour.barcode);
          this.loadDemandeRetour(this.retour.barcode);
        }
      }

      this.loadLivraisonPalettesOrdered(this.retour.id);
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  /*loadLivraison(livraisonId: any) {
    this.livraisonService.getLivraison(livraisonId, this.token)
      .subscribe(
        {
          next: data => this.handleLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('franchises loaded!')
        }
      );
  }

  handleLivraisonServerResponse(response: any) {
    console.log("livraison response--");
    console.log(response);

    if (response?.livraison) this.associatedLivraison = response.livraison;
  }*/

  handleAddRetourSourceServerResponse(response: any = {}) {
    if (response.success) {
      if (this.isAdmin) {
        this.loadRetourSource(this.retour.id, 'centre');
        this.loadRetourSource(this.retour.id, 'franchise');
      } else if (this.isFranchise) {
        this.loadRetourSource(this.retour.id, 'franchise');
      } else if (this.isCentre) {
        this.loadRetourSource(this.retour.id, 'centre');
      }
    }
  }

  handleRetourSourceServerResponse(response: any = {}) {
    // console.log("retour source", response)
    if (response.success) {
      if (Array.isArray(response.retourSource) && response.retourSource.length === 0) {
        if (this.isCentre && response.source === "centre") {
          this.addRetourSource("centre");
          return;
        }
      }
      
      if (response.retourSource) {
        const retourSource = response.retourSource;
        const source = response.source;

        switch (source) {
          case 'franchise': {
            this.retourFranchise = retourSource;
            break;
          }

          case 'centre': {
            this.retourCentre = retourSource;
            break;
          }

          default:
            break;
        }

        this.loadProductsRetourSource(this.retour.id, source); // V1 sans cartons, directement avec les codes-barres produits
      }

      if (this.isRetourReceived) {
        if (this.retour.status === 0) {
          if (this.providers === undefined) {
            this.loadAllProviders();
          }

          if (this.franchises === undefined) {
            this.loadAllFranchises();
          }
        }
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleCartonsServerResponse(response: any = {}) {
    if (response.success) {
      const cartons = response.cartons;
      const id = response.id;
      const source = response.source;

      if (cartons) {
        switch (source) {
          case 'franchise': {
            this.retourFranchise.cartons = cartons.map((c: any) => {
              const c0 = Object.assign({}, c);
              c0.products = [];
              return c0;
            });
            break;
          }

          case 'centre': {
            this.retourCentre.cartons = cartons.map((c: any) => {
              const c0 = Object.assign({}, c);
              c0.products = [];
              console.log('centre | c0', c0);
              return c0;
            });
            break;
          }

          default:
            break;
        }

        this.loadProductsRetourSource(this.retour.id, source);
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleProductsServerResponse(response: any = {}) {
    if (response.success) {
      const products = response.products;
      const id = response.id;
      const source = response.source;

      if (products) {
        switch (source) {
          case 'franchise': {
            this.productsFranchise = products;

            /*products.forEach(p => {
              console.log('p.id', p.id);
              console.log('p.barcode', p.barcode);
              // console.log('p.idCartonRetour', p.idCartonRetour);
              console.log('p.idRetour', p.idRetour);
              console.log('p.scanDate', p.scanDate);

              const cartonsFranchise = this.retourFranchise.cartons.filter(c => c.id === p.idCartonRetour);
              console.log('cartonsFranchise.length FOUND', cartonsFranchise.length);

              if (cartonsFranchise.length === 1) {
                console.log('cartonsFranchise[0]', cartonsFranchise[0]);

                cartonsFranchise[0].products.push(p);

                console.log('this.retourFranchise.cartons');
                // console.table(cartonsFranchise[0].products.filter(c => c.id === p.idCartonRetour));
              }
            });

            // console.log('this.retourFranchise.cartons');
            // console.table(this.retourFranchise.cartons);*/

            break;
          }

          case 'centre': {
            this.productsCentre = products;
            break;
          }

          default:
            break;
        }
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleUpdateRetourServerResponse(response: any = {}) {
    if (response.success) {
      if (response.retour) {
        this.retour = response.retour;
        this.retour.barcode = new RetourBarcodePipe()
          .transform(this.retour.serialNumber, this.retour.provider.id);
        this.originalProvider = Object.assign({}, this.retour.provider);

        if (this.retour.status === 1)
          this.loadDemandeRetour(this.retour.barcode);
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleProviderServerResponse(response: any = {}) {
    if (response.success) {
      this.providers = response.providers.sort((a: { id: number; }, b: { id: number; }) => {
        return a.id - b.id;
      });
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleFranchiseServerResponse(response: any = {}) {
    if (response.success) {
      this.franchises = response.franchises.sort((a: { initiales: number; }, b: { initiales: number; }) => {
        return a.initiales - b.initiales;
      });

      this.franchises.push(<Franchise>{
        id: 0,
        initiales: ''
      });
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleError(error: any = {}) {
    console.error('handleError', error.statusText);
    this.error = error;
  }

  /*updateCarton(carton: CartonRetour) {
    console.log(`Carton ${carton.id} [Status: ${carton.status}] updated with ${carton.products.length} products`);
    console.log(JSON.stringify(carton));
    console.log(JSON.stringify(carton.products));

    this.cartons.push(event);
  }*/

  onChangeProvider(newProvider: string) {

    const provider = this.providers.find((p: { id: string; }) => parseInt(p.id, 10) === parseInt(newProvider, 10));

    this.retour.provider = Object.assign({}, provider);
    this.retour.provider.oldProviderId = this.originalProvider.id;

    this.modifyRetour();
  }

  onChangeFranchise(newFranchise: number) {
    const franchise = this.franchises.find((f: { id: number; }) => f.id === newFranchise);

    this.retour.franchise = franchise;
    console.log(this.retour.franchise);
    
    this.retour.associated_address = null;
    this.addresses = [];

    this.modifyRetour();
    this.loadFranchiseAdresses(franchise.id);
  }

  onChangeAddress(event: any) {
    this.retour.associated_address = this.associatedAddress?.id ?? null;
    // console.log('onChangeAddress | this.associatedAddress', this.associatedAddress);

    if (!this.associatedAddress) {
      // console.warn('onChangeAddress | Aucune adresse sélectionnée.');
      return; // Empêche l'exécution du reste du code si l'adresse est undefined
    }

    this.retour.livraison19T = this.associatedAddress.accessibilite_19T;
    this.retour.livraisonAdresse = this.associatedAddress.adresse
    this.retour.livraisonCodePostal = this.associatedAddress.code_postale
    this.retour.livraisonCompAdresse = this.associatedAddress.complement_adresse
    this.retour.livraisonVille = this.associatedAddress.ville
    this.retour.livraisonLatitude = this.associatedAddress.latitude
    this.retour.livraisonLongitude = this.associatedAddress.longitude
    // console.log('onChangeAddress | this.retour', this.retour);
    this.modifyRetour();
  }

  modifyRetour() {
    this.retourService.updateRetour(this.retour, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateRetourServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('retour was updated!')
        }
      );
  }

  validateRetour() {
    this.retour.status = 1;

    this.retourService.updateRetour(this.retour, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateRetourServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('retour was updated!')
        }
      );
  }

  saveRetour() {
    this.retour.status = 1;

    this.retourService.updateRetour(this.retour, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateRetourServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('retour was updated and saved!')
        }
      );

    // TODO Pas de mise à jour du product ? À voir... (créatino d'une table products_retours)
    /*this.productService.updateProducts(this.products, this.token)
      .subscribe(
        data => {
          this.handleLivraisonServerResponse(data);
        },
        error => {
          this.handleError(error);
        }
      );*/
  }

  startRetour(input: any) {
    this.retour.status = 2;

    this.sendUpdateRetour(this.retour);
  }

  sendRetour(input: any) {
    this.retour.status = 3;

    this.sendUpdateRetour(this.retour);
  }

  sendUpdateRetour(retour: Retour) {
    this.retourService.updateRetour(retour, this.token)
      .subscribe(
        {
          next: data => this.handleServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('retour was updated and sended!')
        }
      );
  }

  paletteChanged(event: any) {
    var self = this;

    var value = event.target.value;
    var form = event.srcElement.form;
    var elementParent = event.srcElement.parentElement;
    var formBox = elementParent.children;
    var buttonBox = elementParent.parentElement.parentElement.lastChild;

    var buttonMinus = formBox[2].firstChild;
    var nbPaletteInput = formBox[3].firstChild;
    var buttonPlus = formBox[4].firstChild;

    for(var i = 0, len = form.length; i < len; i++) {

      if (form[i].getAttribute('id') === 'addPaletteButton') {

        switch (value) {
          case '1': // Cassettes
          case '2': // Personnalisée
          case '3': // 3 produits [P3P]
          case '4': // 4 produits [P4P]
          case '7': // 3 produits B [P3PB]
          case '8': // Baguettes [PBG]
          case '9': // Canettes [PCN]
          case '10': // Fusettes [PFU]
            buttonMinus.removeAttribute('disabled');
            nbPaletteInput.removeAttribute('disabled');
            buttonPlus.removeAttribute('disabled');
            buttonBox.style.visibility = 'visible';
            break;
          default:
            buttonMinus.setAttribute('disabled', '');
            nbPaletteInput.value = "0";
            nbPaletteInput.setAttribute('disabled', '');
            buttonPlus.setAttribute('disabled', '');
            buttonBox.style.visibility = 'hidden';
            return 'Inconnu';
        }

        this.getNbPaletteElement(formBox, function(elemNbPalette: any) {
          var nbPalettes = Number(elemNbPalette.value);

          self.toogleValidateButton(value, nbPalettes);
          /*if (Number.isNaN(Number(value)) && Number(elemNbPalette.value) > 0) {
            form[i].setAttribute('disabled', true);
          } else {
            form[i].removeAttribute('disabled');
          }*/
        });
      }
    }
    return;
  }

  nbPaletteInputed(event: any) {
    // console.log("nbPaletteInputed event console 1")
    var self = this;

    if (event.data !== null) {
      if (isNaN(event.data)) {
        // console.log("nbPaletteInputed event console 2")

        event.stopPropagation();
      }

      var activeFormRow: any = document.getElementById('active-form-row');
      // console.log("nbPaletteInputed event console 3")
      // console.log('nbPaletteInputed | activeFormRow', activeFormRow);

      var formBox = activeFormRow.children[0].children[0].children;
      // console.log('nbPaletteInputed | formBox', formBox);
      // console.log("nbPaletteInputed event console 4")

      var elemNbPalette = this.getNbPaletteElementX(formBox, false);
      // console.log("nbPaletteInputed event console 5")

      var typePalette = this.getTypePaletteValueX(formBox, false);
      // console.log("nbPaletteInputed event console 6")

      this.toogleValidateButton(typePalette, Number(elemNbPalette.value));
    }
  }

  getTypePaletteValueX(activeRow: any, isValidate: boolean) {
    var element = activeRow[1];
    var modelePaletteValue = parseInt(element.options[element.selectedIndex].value, 10);
    return modelePaletteValue;
  };

  getTypePaletteValue(activeRow: any, callback: any) {
    for(var i = 0; i< activeRow.length; i++) {
      var element = activeRow[i];

      if (element.getAttribute('id') === 'inputGroupSelectPalette') {
        var modelePaletteValue = parseInt(element.options[element.selectedIndex].value, 10);
        callback(modelePaletteValue);
      }
    }
  };

  getNbPaletteElementX(activeRow: any, isValidate: any) {
    var element = (isValidate) ? activeRow[2] : activeRow[3];
    var subElement = element.firstChild;
    return subElement;
  }

  getNbPaletteElement(activeRow: any, callback: any) {
    for(var i = 0; i< activeRow.length;i++) {
      var element = activeRow[i];

      if (element.getAttribute('id') === 'nbPalettes') {
        callback(element.children[0]);
      }
    }
  }

  minusClicked(event: any) {
    var activeRow = event.srcElement.parentElement.parentElement.children;
    var self = this;

    this.getNbPaletteElement(activeRow, function(elemNbPalette: any) {
      var nbPalettes = elemNbPalette.value;
      if (nbPalettes > 0) {
        nbPalettes = Number(nbPalettes) - 1;
        elemNbPalette.value = nbPalettes;
      }

      self.getTypePaletteValue(activeRow, function(modelePaletteValue: any) {
        self.toogleValidateButton(modelePaletteValue, nbPalettes);
      });
    });
  }

  plusClicked(event: any) {
    var activeRow = event.srcElement.parentElement.parentElement.children;
    var self = this;

    this.getNbPaletteElement(activeRow, function(elemNbPalette: any) {
      var nbPalettes = elemNbPalette.value;
      nbPalettes = Number(nbPalettes) + 1;
      elemNbPalette.value = nbPalettes;

      self.getTypePaletteValue(activeRow, function(modelePaletteValue: any) {
        self.toogleValidateButton(modelePaletteValue, nbPalettes);
      });
    });
  }

  toogleValidateButton(modelePaletteValue: any, nbPalettes: number) {
    // console.log("toogleValidateButton ")

    var addPaletteButton: any = document.getElementById('addPaletteButton');

    if (!Number.isNaN(Number(modelePaletteValue)) && Number(nbPalettes) > 0) {
      // console.log('ACTIVATE BUTTON');
      addPaletteButton.removeAttribute('disabled');
    } else {
      // console.log('DISABLE BUTTON');
      addPaletteButton.setAttribute('disabled', '');
    }
  }

  validatePalette(event: any) {
    // console.log("event validatePalette")
    var activeRow = event.srcElement.parentElement.parentElement.children[0].children[0].children;
    
    var self = this;

    this.getNbPaletteElement(activeRow, function(elemNbPalette: any = {}) {
      var nbPalettes = elemNbPalette.value;
      self.getTypePaletteValue(activeRow, function(modelePaletteValue: any) {

        var obj = { modelePalette: modelePaletteValue, nbPalettes: Number(nbPalettes) }
        
        var findedObj: any = self.orderPalettes.find((m: { modelePalette: any; }) => m.modelePalette === modelePaletteValue);

        if (typeof findedObj === "undefined") {
          self.orderPalettes.push(obj);
        } else {
          findedObj.nbPalettes += Number(nbPalettes);
        }

        self.putOrderPalettes(obj);
      });
    });
  }

  removePalette(event: any) {
    var parentElementActiveRow = event.srcElement.parentElement.parentElement;
    var activeRow = parentElementActiveRow.children[0].children[0].children;

    var elemNbPalette = this.getNbPaletteElementX(activeRow, true);
    var nbPalettes = elemNbPalette.value;

    var modelePaletteValue = this.getTypePaletteValueX(activeRow, true);

    var findedObj: any = this.orderPalettes.find((m: { modelePalette: number; }) => m.modelePalette === modelePaletteValue);
    findedObj.nbPalettes -= nbPalettes;

    if (findedObj.nbPalettes === 0) {
      this.orderPalettes = this.orderPalettes.filter((m: { modelePalette: number; }) => m.modelePalette !== modelePaletteValue);

      this.deleteOrderPalettes(findedObj.modelePalette);
    }

    parentElementActiveRow.remove();
    this.recalculateNbPalettes();

    /*var dataToPatch = [
      { "op": "remove", "modelePalette": modelePaletteValue }
    ];

    this.patchDataPalette(dataToPatch);*/
  }

  putOrderPalettes(data: any) {
    this.retourService.putOrderPalettes(this.retour.id, data, this.token)
      .subscribe(
        {
          next: data => this.handlePutOrderPalettesServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('order palettes loaded!')
        }
      );
  }

  deleteOrderPalettes(modelePalette: any) {
    this.retourService.deleteOrderPalettes(this.retour.id, modelePalette, this.token)
      .subscribe(
        {
          next: data => this.handleDeleteOrderPalettesServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('delete order palette loaded!')
        }
      );
  }

  recalculateNbPalettes() {
    this.retour.nbPalettes = 0
  
    for(let p of this.orderPalettes) {
      this.retour.nbPalettes += p.nbPalettes;
    }
  }

  handlePutOrderPalettesServerResponse(response: any = {}) {
    if (response.success) {
      this.recalculateNbPalettes();
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleDeleteOrderPalettesServerResponse(response: any = {}) {
    if (response.success) {
      this.recalculateNbPalettes();
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleLivraisonPalettesOrderedServerResponse(response: any = {}) {
    if (response.success) {
      if (response.orderPalettes) {
        if (this.retour) {
          this.orderPalettes = response.orderPalettes;
          this.isRetourPalettesOrderedReceived = true;

          this.recalculateNbPalettes();
          /*if (this.livraison.status >= 1
            && this.livraison.status !== 6
            && this.isLivraisonReceived
            && this.isRetourPalettesOrderedReceived) {
              this.loadBonLivraison();
          }*/
        }
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handlePaletteModelesServerResponse(response: any = {}) {

    if (response.success) {
      this.paletteModeles = response.paletteModeles.sort((a: { id: number; }, b: { id: number; }) => {
        return a.id - b.id;
      });
      // console.log('this.paletteModeles', this.paletteModeles)

    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  loadLivraisonPalettesOrdered(id: number) {
    this.retourService.getLivraisonPalettesOrdered(id, this.token).subscribe(
      {
        next: data => this.handleLivraisonPalettesOrderedServerResponse(data),
        error: err => this.handleError(err),
        // complete: () => console.log('palettes ordered livraison loaded!')
      }
    );
  }

  loadAllPaletteModeles() {
    this.paletteService.getPaletteModeles(this.token)
      .subscribe(
        {
          next: data => this.handlePaletteModelesServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('palette model loaded!')
        }
      );
  }

  generatePdfDemandeRetour(retour: any) {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    this.retourService.createDemandeRetour(retour, token).subscribe({
      next: data => this.toCheckDemandeRetourPdf(data),
      error: err => console.error("ERROR IN FRONT", err),
      // complete: () => console.log("bon livraison created!")
    })
  }
  
  toCheckDemandeRetourPdf(data: any) {
    this.loadDemandeRetour(data.docDemandeRetour.barcode)
  }

  loadDemandeRetour(barcode: string) {
    // console.log("in loadDemandeRetour : " + barcode);
    
    this.retourService.getDocument(barcode, this.token).subscribe(
      {
        next: data => this.handleGetFileServerResponse(data),
        error: err => this.handleError(err),
        // complete: () => console.log('document livraison loaded!')
      }
    );
  }

  handleGetFileServerResponse(response: any = {}) {
    // console.log("RESPONSE HANDLE FILE pls", response)
    if (!response.success) {
      // Les fichiers n'existent pas, on génère les PDF
      this.generatePdfDemandeRetour(this.retour);
    } else {
      // Le fichier existe, on ajoute le blob
      if (response.file) {
        this.demandeRetourPdfBase64 = response.file;
        this.blobDemandeRetour = b64toBlob(this.demandeRetourPdfBase64, 'application/pdf');
      }
    }
  }

  loadFranchiseAdresses(id: number) {
    this.franchiseService.getAllAdressesLivraisonByFranchise(id, this.token)
      .subscribe(
        {
          next: data => this.handleFranchiseAdressesServerResponse(data),
          error: err => this.handleError(err),
          // complete: () => console.log('franchise adresses loaded!')
        }
      )
  }

  handleFranchiseAdressesServerResponse(response: any = {}) {
    const addresses: any[] = response.adresses;

    this.associatedAddress = addresses.find(a => a.id === this.retour.associated_address);

    for (let i = 0; i <= addresses.length - 1; i++) {
      if (addresses[i].isActif === 1) {
        this.addresses.push(addresses[i]);
      }
    }
    // console.log('addresses', addresses);
    // console.log('active addresses', addresses);
  }

  showPdfDemandeRetour() {
    const fileURL = URL.createObjectURL(this.blobDemandeRetour);
    window.open(fileURL, '_blank');
  }

  showPdfAttestationHonneur(): void {
    if (!this.retour?.serialNumber || !this.token) return;
  
    // 👉 Ouvre une nouvelle fenêtre immédiatement (autorisé par les navigateurs)
    const newWindow = window.open('', '_blank');
    if (!newWindow) {
      alert("Le pop-up a été bloqué. Merci d'autoriser les pop-ups pour ce site.");
      return;
    }
  
    // 📥 Télécharge le PDF en arrière-plan
    this.retourService.getAttestationHonneur(this.retour.serialNumber, this.token).subscribe({
      next: (blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        newWindow.location.href = url;
      },
      error: (err) => {
        newWindow.close();
        console.error('Erreur lors de l’ouverture du PDF d’attestation :', err);
      }
    });
  }

  livraisonBarcodePipe(livraison: any) {
    return new LivraisonBarcodePipe().transform(livraison.serialNumber, livraison.provider.id);
  }

  livraisonStatusPipe(livraison: any) {
    return new LivraisonStatusPipe().transform(livraison.status);
  }

  livraisonDate(livraison: any) {
    return new Date(livraison.creationDate).toLocaleString();
  }
}

const b64toBlob = (b64Data: any, contentType: string = '', sliceSize: number = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
};
