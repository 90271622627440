import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { InventaireService } from '../services/inventaire.service';
import { InventaireFranchise } from '../model/inventaireFranchise';
import { Provider } from '../model/provider';

@Component({
    selector: 'app-inventaire-franchise',
    templateUrl: './inventaire-franchise.component.html',
    styleUrls: ['./inventaire-franchise.component.css'],
    standalone: false
})
export class InventaireFranchiseComponent implements OnInit, AfterViewChecked {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  isFranchise: boolean = false;
  isCentre: boolean = false;
  userIsLoggedin: boolean = false;

  inventaire: any
  originalProvider: Provider = new Provider(0, '', 0);
  franchise: any;
  cartons: [] = [];
  products: [] = [];
  error: any = {};
  errorHandled: any = {};
  source: string = '';
  parametersObservable: any;

  isinventaireReceived: boolean = false;

  constructor(private inventaireService: InventaireService,
    private ref: ChangeDetectorRef,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') ||'');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      // console.log(this.decodedToken);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      if (this.decodedToken && this.decodedToken.role.nom === 'franchise') {
        this.isFranchise = true;
      }
      if (this.decodedToken && this.decodedToken.role.nom === 'centre') {
        this.isCentre = true;
      }

      // admin needs to see all ads
      if (this.isAdmin) {
        // this.loadInventaire();
      } else {
        if (this.isFranchise) {
          this.loadInventaire();
        }
      }
    }
  }

  ngAfterViewChecked(): void {
    // explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
    this.ref.detectChanges();
  }

  loadInventaire() {
    this.inventaireService.getInventaire(this.token)
      .subscribe({
        next: (data) => this.handleInventaireServerResponse(data),
        error: (err) => this.handleError(err),
        // complete: () => console.log("inventaire loaded!")
      }
      );
  }

  loadCartonsInventaire(id: any) {
    // console.log(`loadCartonsInventaire ID : ${id}`);
    this.inventaireService.getCartonsFromInventaire(id, this.token)
      .subscribe(
        {
          next: (data) => this.handleCartonsServerResponse(data),
          error: (err) => this.handleError(err),
          // complete: () => console.log("cartons inventaire loaded!")
        }
      );
  }

  loadProductsInventaire(id: any) {
    // console.log(`loadProductsRetourSource ID : ${id}`);
    this.inventaireService.getProductsFromInventaire(id, this.token)
      .subscribe(
        {
          next: (data) => this.handleProductsServerResponse(data),
          error: (err) => this.handleError(err),
          // complete: () => console.log("products inventaire loaded!")
        }
      );
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  handleInventaireServerResponse(response: any = {}) {
    // console.log('handleInventaireServerResponse ', response);

    if (response.success) {
      if (response.inventaire) {
        this.inventaire = response.inventaire;
        /*this.inventaire.barcode = new RetourBarcodePipe()
          .transform(this.inventaire.serialNumber, this.inventaire.provider.id);

        console.log('this.inventaire', this.inventaire);

        this.originalProvider = Object.assign({}, this.inventaire.provider);
        console.log('originalProvider', this.originalProvider);*/

        this.isinventaireReceived = true;

        this.loadCartonsInventaire(this.inventaire.id);
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleCartonsServerResponse(response: any = {}) {
    // console.log('handleCartonsServerResponse ', response);

    if (response.success) {
      // console.log('IL Y A DES CARTONS !');
      const cartons = response.cartons;
      const id = response.id;

      this.inventaire.cartons = cartons.map((c: any) => {
        const c0 = Object.assign({}, c);
        c0.products = [];
        // console.log('franchise | c0', c0);
        return c0;
      });

      // console.table(this.inventaire.cartons);
      // console.log('handleCartonsServerResponse | this.inventaire.cartons', this.inventaire.cartons);

      this.loadProductsInventaire(this.inventaire.id);
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleProductsServerResponse(response: any = {}) {
    // console.log('handleProductsServerResponse ', response);

    if (response.success) {
      // console.log('IL Y A DES PRODUCTS !');
      const products = response.products;
      const id = response.id;
      const source = response.source;

      this.products = products;
      // console.log('this.products', this.products);

      products.forEach((p: { id: any; barcode: any; status: any; idCarton: any; scanDate: any; }) => {
        /*console.log('p.id', p.id);
        console.log('p.barcode', p.barcode);
        console.log('p.status', p.status);
        console.log('p.idCarton', p.idCarton);
        console.log('p.scanDate', p.scanDate);*/

        const cartons = this.inventaire.cartons.filter((c: { id: any; }) => c.id === p.idCarton);
        // console.log('cartons.length FOUND', cartons.length);

        if (cartons.length === 1) {
          // console.log('cartons[0]', cartons[0]);

          cartons[0].products.push(p);

          // console.log('this.inventaire.cartons');
          console.table(cartons[0].products.filter((c: { id: any; }) => c.id === p.idCarton));
        }
      });

      // console.log('this.inventaire.cartons');
      console.table(this.inventaire.cartons);
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleError(error: any = {}) {
    console.error('handleError', error.statusText);
    this.error = error;
  }

}
