import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'demandeLivraisonStatus',
    standalone: false
})
export class DemandeLivraisonPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 0:
        return 'En cours de création';
      case 1:
        return 'En attente';
      case 2:
        return 'Validée'
      default:
        return 'Inconnu';
    }
  }

}
