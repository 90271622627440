<!-- <h3>TEST</h3> -->
<ng-container *ngIf="livraison">
    <ng-container *ngIf="livraison.franchise">
  
  
      <ng-container *ngIf="!isAdmin && (palettes && (palettes?.length >= 0) && (palettes?.length <= (livraison.nbPalettes-1)) && (livraison.status === 1) )">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Scan du code-barre</h5>
                <h6 class="card-subtitle mb-2 text-muted">Veuillez scanner le code-barre de la Palette</h6>
                <input type="text" class="form-control" #barcodeInput (keyup)="onKeyBarcode(barcodeInput)" title="scanInput" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" autofocus>
              </div>
            </div>
          </div>
        </div>
  
        <br>
      </ng-container>
  
     <!-- <ng-container *ngIf="!isAdmin && (palettes && (palettes?.length >= 0) && (palettes?.length <= (livraison.nbPalettes-1)) && (livraison.status === 1) )">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Scan du code-barre</h5>
                <h6 class="card-subtitle mb-2 text-muted">Veuillez scanner le code-barre de la Palette</h6>
                <p class="card-text" #scanStatus id="scanStatus">En attente du scan</p>
                <input #barcodeInput name="barcodeInput" type="text" class="form-control" (keyup)="onKeyBarcode(barcodeInput)" title="scanInput" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" autofocus>
              </div>
            </div>
          </div>
        </div>
      
        <br>
      </ng-container> -->
  
      <div class="row">
        <div class="col">
          <div *ngIf="palettes?.length > 0">
      
            <div class="card">
              <div class="card-body">
                <ng-container *ngIf="livraison.creationDate > dateAjoutModelesPalette; else oldModelePalette">
                  <ng-container *ngFor="let op of orderPalettes" [ngSwitch]="+op.modelePalette">
                    <ng-container *ngIf="op.nbPalettes === 1">
                      <h3>Palette {{op.modelePalette | paletteModele}}</h3>
                    </ng-container>
                    <ng-container *ngIf="op.nbPalettes > 1">
                      <h3>Palette {{op.modelePalette | paletteModele}}</h3>
                    </ng-container>
                    <h4 *ngSwitchCase="1" class="card-subtitle mb-2 text-muted">{{ palettesModeleK7.length || '0' }} sur {{op.nbPalettes}}</h4>
                    <h4 *ngSwitchCase="2" class="card-subtitle mb-2 text-muted">{{ palettesModeleCustom.length || '0' }} sur {{op.nbPalettes}}</h4>
                    <h4 *ngSwitchCase="3" class="card-subtitle mb-2 text-muted">{{ palettesModeleP3P.length || '0' }} sur {{op.nbPalettes}}</h4>
                    <h4 *ngSwitchCase="4" class="card-subtitle mb-2 text-muted">{{ palettesModeleP4P.length || '0' }} sur {{op.nbPalettes}}</h4>
                  
                    <br>
        
                    <div class="row">
                      <div *ngFor="let p of palettes" class=" mb-3 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                        <ng-container *ngIf="p.modele === op.modelePalette">
                          <input (click)="openPaletteDetail($event, p.id)" value="{{p.serialNumber | paletteBarcode: p.provider.id}}" type="text" maxlength="10" class="text-center form-control numFSinput input-palette" aria-label="Small" aria-describedby="inputGroup-sizing-sm" style="text-transform:uppercase" autocomplete="off" readonly="" title="resultInput">
            
                          <ng-container *ngIf="!isAdmin">
                            <div *ngIf="livraison.status === 0" >
                              <button appNoDblClick (click)="removePalette($event)" class="btn btn-outline-secondary fs-delete" type="button">
                                <i class="bi bi-x-lg"></i>
                              </button>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #oldModelePalette>
                  <ng-container *ngIf="palettes.length === 1">
                    <h3>Palette</h3>
                  </ng-container>
                  <ng-container *ngIf="palettes.length > 1">
                    <h3>Palettes</h3>
                  </ng-container>
                  <h4 class="card-subtitle mb-2 text-muted">{{ palettes.length || '0' }} sur {{livraison.nbPalettes}}</h4>
    
                  <br>
    
                  <div class="fs-group row">
                    <div *ngFor="let p of palettes" class="input-group input-group-sm mb-3 col-sm-12 col-md-4 col-lg-2 col-xl-2">
                      <input (click)="openPaletteDetail($event, p.id)" value="{{p.serialNumber | paletteBarcode: p.provider.id}}" type="text" maxlength="10" class="text-center form-control numFSinput input-palette" aria-label="Small" aria-describedby="inputGroup-sizing-sm" style="text-transform:uppercase" autocomplete="off" readonly="" title="resultInput">
    
                      <ng-container *ngIf="!isAdmin">
                        <div *ngIf="livraison.status === 0" >
                          <button appNoDblClick (click)="removePalette($event)" class="btn btn-outline-secondary fs-delete" type="button">
                            <i class="bi bi-x-lg"></i>
                          </button>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-template>
                
              </div>
            </div>
      
          </div>
        </div>
      
      </div>
  
    </ng-container>
  </ng-container>
  