import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AuthService} from './auth.service';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocieteService {

  societeSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) { }

  addSocieteAdresseFacturation(societeData: any, token: string) {
    console.log("addSociete => ", societeData);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + '/api/societe/adresse/facturation', societeData, { headers })
      .pipe(
        map((res: any) => {
          console.log("res ", res);
          this.societeSubject.next(res);
          return res;
        })
      );
  }

  addSocieteAdresseLivraison(societeAddressData: any, token: string) {
    console.log("addSocieteAdresse => ", societeAddressData);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + '/api/societe/adresse/livraison', societeAddressData, { headers })
      .pipe(
        map((res: any) => {
          console.log("res ", res);
          this.societeSubject.next(res);
          return res;
        })
      );
  }

  updateSocieteAdresseFacturation(id: number, societeData: any, token: string) {
    console.log("updateSocieteAdresseFacturation => ", id);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/societe/adresse/facturation/${id}`, societeData, { headers })
      .pipe(
        map((res: any) => {
          console.log("res ", res);
          this.societeSubject.next(res);
          return res;
        })
      );
  }

  updateSocieteAdresseLivraison(id: number, societeData: any, token: string) {
    console.log("updateSocieteAdresseLivraison => ", societeData);

    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/societe/adresse/livraison/${id}`, societeData, { headers })
      .pipe(
        map((res: any) => {
          console.log("res ", res);
          this.societeSubject.next(res);
          return res;
        })
      );
  }
}
