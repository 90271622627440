import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AuthService} from './auth.service';
import { environment } from '../../environments/environment';
import {Subject} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FranchiseService {

  initialFranchises = [];
  franchises = [];
  franchisesSubject = new Subject();
  searchResultSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getFranchises(token: string) {
    console.log('getFranchises');
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/franchises', { headers });
  }

  getFranchisesInactives(token: string) {
    console.log('getFranchises');
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/franchises/inactifs', { headers });
  }

  getFranchiseByInitiales(initiales: string, token: string) {
    console.log("getFranchiseByInitiales")
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/franchise/initiales/${initiales}`, { headers });

  }

  getFranchise(id: number, token: string) {
    console.log("getFranchise")
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/franchise/${id}`, { headers });
  }

  getAdresseLivraisonByFranchise(id_address: number, id_franchise: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/franchise/${id_franchise}/adresse/livraison/${id_address}`, { headers });
  }
  getAdresseFacturationByFranchise(id_address: number, id_franchise: number, token: string) {
    console.log('service id_address', id_address)
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/franchise/${id_franchise}/adresse/facturation/${id_address}`, { headers });
  }

  getAllAdressesByFranchise(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/franchise/${id}/adresses`, { headers });
  }

  getAllAdressesFacturationByFranchise(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/franchise/${id}/adresses/facturation`, { headers });
  }

  getAllAdressesLivraisonByFranchise(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/franchise/${id}/adresses/livraison`, { headers });
  }

  addFranchise(franchiseData: any, token: string) {
    console.log("addfranchise => ", franchiseData);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + '/api/franchise', franchiseData, { headers })
      .pipe(
        map((res: any) => {
          console.log("res ", res);
          this.franchisesSubject.next(res);
          return res;
        })
      );
  }

  updateFranchise(id: number, franchiseData: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/franchise/${id}`, franchiseData, { headers })
      .pipe(
        map((res: any) => {
          console.log("res ", res);
          this.franchisesSubject.next(res);
          return res;
        })
      );
  }
  
}
